<template>
  <div v-if="getOviewviewImages().length > 0" class="p-b10">
    <hr />
    <div class="section-head inner-haed">
      <h2 class="text-uppercase">Gallerien</h2>
    </div>
    <div class="section-content">
      <div class="clearfix">
        <ul
          id="masonry"
          class="row dez-gallery-listing gallery-grid-4 m-b0 mfp-gallery"
        >
          <li
            class="card-container col-lg-4 col-sm-6"
            :key="item.gallery_id.id"
            v-for="item of getOviewviewImages()"
          >
            <div class="dez-box dez-gallery-box">
              <div class="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                <router-link :to="'/galleries/' + item.gallery_id.id">
                  <img
                    :src="getImage(item.gallery_id.overview_image, 'x700y440')"
                    :alt="item.gallery_id.title"
                /></router-link>
                <div class="overlay-bx">
                  <div class="overlay-icon">
                    <router-link :to="'/galleries/' + item.gallery_id.id">
                      <i class="fa fa-link icon-bx-xs"></i>
                    </router-link>
                    <div class="description">
                      {{ item.gallery_id.title }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-description" v-show="item.gallery_id.description">
                <router-link :to="'/galleries/' + item.gallery_id.id">
                  {{ item.gallery_id.description }}
                </router-link>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getImage } from "../../lib/helper";
export default defineComponent({
  props: {
    section: Object,
  },
  methods: {
    getImage,
    getOviewviewImages() {
      if (!this.section.galleries) {
        return [];
      }
      return this.section.galleries.filter((gallery) => {
        return (
          gallery.gallery_id &&
          gallery.gallery_id !== null &&
          gallery.gallery_id.show_on_overview
        );
      });
    },
  },
});
</script>

<style scoped lang="scss">
.description {
  background: white;
  margin-top: 20px;
  border-radius: 6px;
  padding: 5px;
  font-family: Oswald, sans-serif;
}
.d-description {
  text-align: center;
  background: white;
  border-radius: 6px;
  padding: 5px;
  font-family: Oswald, sans-serif;
}
</style>
