import api from "./api";

const getItems = async (params) => {
  params = params ? params : {};
  params.fields = "*";
  return await api.get(`items/tennis_calendar${api.addParams(params)}`);
};

const addItem = async (data) => {
  return await api.post("items/tennis_calendar", data);
};

const updateItem = async (id, data) => {
  return await api.patch(`items/tennis_calendar/${id}`, data);
};

const removeItem = async (id) => {
  return await api.remove(`items/tennis_calendar/${id}`);
};

const getItemByHash = async (hash) => {
  let params = [];
  params["filter[hash._eq]"] = hash;
  const result = await api.get(`items/tennis_calendar${api.addParams(params)}`);
  return result.data ? result.data.shift() : false;
};

const getSettings = async (params) => {
  params = params ? params : {};
  params.fields = "*";
  return await api.get(
    `items/tennis_calendar_settings${api.addParams(params)}`
  );
};

const tennis_calendar = {
  getItems,
  addItem,
  updateItem,
  removeItem,
  getItemByHash,
  getSettings,
};

export default tennis_calendar;
