<template>
  <div v-if="this.items.length > 0" class="p-b10">
    <hr />
    <div class="section-head inner-haed">
      <h2 class="text-uppercase">News</h2>
    </div>
    <div class="section-content">
      <div
        class="owl-carousel owl-theme blog-carousel mfp-gallery gallery owl-btn-center-lr"
      >
        <div :key="item.id" v-for="item of items" class="item">
          <div class="ow-blog-post date-style-2">
            <div class="ow-post-media dez-img-effect zoom-slow">
              <router-link class="site-button-link" :to="'/news/' + item.id">
                <img
                  :src="getImage(item.image, 'x700y440')"
                  :alt="item.title"
                />
              </router-link>
            </div>
            <div class="ow-post-info">
              <div class="ow-post-title">
                <h4 class="post-title">
                  <router-link :to="'/news/' + item.id">
                    {{ item.title }}
                  </router-link>
                </h4>
              </div>
              <div class="ow-post-meta">
                <ul>
                  <li class="post-date">
                    <i class="fa fa-calendar"></i
                    ><strong>{{ getDate(item.date_created, "DD MMM") }}</strong>
                    <span>{{ getDate(item.date_created, "YYYY") }}</span>
                  </li>
                </ul>
              </div>
              <div class="ow-post-text">
                <p>{{ cutString(item.description, 100) }}</p>
              </div>
              <div class="ow-post-readmore">
                <router-link class="site-button-link" :to="'/news/' + item.id">
                  MEHR LESEN <i class="fa fa-angle-double-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import news from "../../lib/api/news";
import { getImage, cutString, getDate, strippedString } from "../../lib/helper";
export default defineComponent({
  methods: {
    getImage,
    cutString,
    strippedString,
    getDate,
    async fetchItems(slug) {
      const result = await news.getNewsBySlug(slug);
      this.items = result.data;
    },
  },
  data() {
    return {
      items: [],
    };
  },
  async mounted() {
    this.fetchItems(this.$route.params.slug);
  },

  watch: {
    $route(route) {
      this.fetchItems(route.params.slug);
      window.jQuery(".blog-carousel").trigger("destroy.owl.carousel");
    },
  },

  async updated() {
    window.jQuery(".blog-carousel").owlCarousel({
      center: false,
      loop: false,
      margin: 30,
      autoplaySpeed: 1000,
      navSpeed: 1000,
      paginationSpeed: 1000,
      slideSpeed: 1000,
      smartSpeed: 1000,
      autoplay: 1000,
      nav: true,
      dots: true,
      navText: [
        '<i class="fa fa-chevron-left"></i>',
        '<i class="fa fa-chevron-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        480: {
          items: 2,
        },
        1024: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });
  },
});
</script>
