<template>
  <div class="dez-accordion toggle border-bottom no-border no-cover">
    <AccordionItem
      id="overview"
      label="Übersicht"
      icon_class="fa fa-cog"
      :link="'/sections/' + section.slug"
    />
    <div v-if="!team.id">
      <div v-if="section.teams && section.teams.length > 0">
        <div class="team_row" :key="team.id" v-for="team in section.teams">
          <AccordionItem
            :label="team.teams_id.team"
            icon_class="fa fa-users"
            :link="'/sections/' + section.slug + '/teams/' + team.teams_id.slug"
          />
        </div>
      </div>
      <div v-if="section.pages && section.pages.length > 0">
        <AccordionItem
          :key="page.id"
          v-for="page in section.pages"
          :id="getSlug(page.pages_id.slug)"
          :label="page.pages_id.title"
          icon_class="fa fa-file"
          :link="'/sections/' + section.slug + '/' + page.pages_id.id"
        />
      </div>

      <div v-if="section.galleries && section.galleries.length > 0">
        <div>
          <AccordionItem
            id="galleries"
            label="Gallerie"
            icon_class="fa fa-image"
          >
            <div
              class="gallery_row"
              :key="gallery.id"
              v-for="gallery in section.galleries"
            >
              <router-link
                :to="'/galleries/' + gallery.gallery_id.id"
                class="gallery-item text-primary"
              >
                {{ gallery.gallery_id.title }} ({{
                  gallery.gallery_id.images.length
                }})
              </router-link>
            </div>
          </AccordionItem>
        </div>
      </div>

      <AccordionItem
        v-if="section.training_times && section.training_times.length > 0"
        id="training_times"
        label="Trainingszeiten"
        icon_class="fa fa-clock-o"
      >
        <div
          class="tt_row"
          :key="times.id"
          v-for="times in section.training_times"
        >
          <div class="weekday text-primary">
            {{ times.training_times_id.weekday }}
            <div class="comment" v-if="times.training_times_id.comment">
              ({{ times.training_times_id.comment }})
            </div>
          </div>
          <div class="time">
            {{ getTime(times.training_times_id.time, "HH:mm") }}
            {{
              times.training_times_id.time_to
                ? " - " +
                  getTime(times.training_times_id.time_to, "HH:mm") +
                  " Uhr"
                : " Uhr"
            }}
          </div>
        </div>
      </AccordionItem>
      <AccordionItem
        id="contact"
        label="Kontakt"
        icon_class="fa fa-address-book-o"
        v-if="section.contact && section.contact.length > 0"
      >
        <div
          class="contact_row"
          :key="contact.id"
          v-for="contact in section.contact"
        >
          <div class="text-primary bold">
            {{ contact.contact_id.contact_person }}
          </div>
          <div class="grid">
            <i class="fa fa-envelope text-primary"></i>
            {{ contact.contact_id.email }}
          </div>
          <div class="grid" v-if="contact.contact_id.phone">
            <i class="fa fa-phone-square text-primary"></i>
            <a :href="'tel:' + contact.contact_id.phone">{{
              contact.contact_id.phone
            }}</a>
          </div>
          <div
            v-if="contact.contact_id.street && contact.contact_id.house_number"
            class="grid"
          >
            <i class="fa fa-road text-primary"></i>
            {{ contact.contact_id.street }}
            {{ contact.contact_id.house_number }}
          </div>
          <div
            v-if="contact.contact_id.zip && contact.contact_id.city"
            class="grid"
          >
            <i class="fa fa-address-book-o text-primary"></i>
            {{ contact.contact_id.zip }} {{ contact.contact_id.city }}
          </div>
          <div class="mt-3 text-primary bold right">
            <router-link
              :to="'/contact/' + section.slug + '/' + contact.contact_id.id"
              >Zum Kontaktformular</router-link
            >
          </div>
        </div>
      </AccordionItem>
      <AccordionItem
        v-if="section.slug === 'tennis' && tennis_settings.show_calendar"
        id="tennis"
        label="Platzkalender"
        icon_class="fa fa-calendar"
        :link="'/sections/' + section.slug + '/calendar'"
      />
      <AccordionItem
        v-if="section.external_url && section.external_url !== ''"
        id="external_link"
        label="Homepage"
        icon_class="fa fa-link"
      >
        <div class="link_row">
          <div class="mt-3 text-primary bold">
            <a target="_blank" :href="section.external_url">{{
              section.external_url
            }}</a>
          </div>
        </div>
      </AccordionItem>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getTime } from "../../lib/helper";
import AccordionItem from "../misc/AccordionItem.vue";
import tennis_calendar_settings from "../../lib/api/tennis_calendar";
import slugify from "limax";

export default defineComponent({
  methods: {
    getTime,
    getSlug: function (slug) {
      return slugify(slug);
    },
  },
  data: function () {
    return {
      tennis_settings: {
        show_calendar: false,
      },
    };
  },
  components: {
    AccordionItem,
  },
  props: {
    section: Object,
    team: Object,
  },
  async mounted() {
    const settingsResult = await tennis_calendar_settings.getSettings();
    this.tennis_settings = settingsResult.data;
  },
});
</script>

<style lang="scss" scoped>
@import "../../../public/css/variables.scss";
.dez-accordion {
  border-bottom: none !important;
}
.gallery_row {
  font-family: Oswald, sans-serif;
  &:not(:last-child) {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: dotted 1px lightgray;
  }
}
.tt_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  &:not(:last-child) {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: dotted 1px lightgray;
  }
  .time {
    text-align: right;
  }
}
.link_row {
  text-decoration: underline;
}
.contact_row {
  margin-bottom: 10px;
  padding-bottom: 5px;

  border-bottom: dotted 1px lightgray;
  > div {
    margin-bottom: 5px;
    &.grid {
      display: grid;
      grid-template-columns: 30px 1fr;
      align-items: center;
    }
    &:first-child {
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: solid 1px $primary;
    }
  }
  .right {
    text-align: right;
    a {
      color: gray !important;
    }
  }
}

.comment {
  color: gray !important;
  font-style: italic;
}
</style>
