<template>
  <div class="page-content">
    <!-- inner page banner -->
    <div
      class="dez-bnr-inr overlay-black-middle"
      :style="`background-image: url(${getImage(
        section.main_image,
        'x2000y1200'
      )}); background-position: ${getMainImagePosition(
        section.main_image_position
      )}`"
    >
      <div class="container">
        <div class="dez-bnr-inr-entry">
          <h1 class="text-primary">Sektion</h1>
          <h1 class="text-white">{{ section.title }}</h1>
        </div>
      </div>
    </div>

    <!-- inner page banner END -->
    <!-- Breadcrumb row -->
    <Breadcrumb
      v-bind:data="[
        { label: 'Sektionen', url: '/sections' },
        { label: section.title },
      ]"
    />
    <div class="content-area">
      <div class="container">
        <div class="row">
          <!-- Side bar start -->
          <div class="col-lg-4">
            <aside class="side-bar">
              <div
                class="widget icon-bx-wraper bx-style-1 p-a20 widget_services"
              >
                <h5 class="widget-title">{{ section.title }}</h5>
                <SectionNavigation :section="section" :team="{}" />
              </div>
            </aside>
          </div>
          <!-- Side bar END -->
          <!-- Left part start -->
          <div class="col-lg-8">
            <div>
              <h5 class="m-t0">
                TSV Wustrow
                <div class="text-primary">{{ section.title }}</div>
              </h5>
              <div class="dez-separator bg-primary"></div>
              <p>
                <strong
                  v-html="
                    section.status === 'draft'
                      ? 'Wir sind dabei unsere Inhalte zu überarbeiten. Nähere Informationen kommen in Kürze.'
                      : section.short_description
                  "
                ></strong>
              </p>
            </div>
            <div v-if="section.status !== 'draft'">
              <div v-html="section.description"></div>
            </div>
            <OverviewGallery :section="section" :team="{}" />
            <NewsSlider />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getImage } from "../../lib/helper";
import sections from "../../lib/api/sections";
import Breadcrumb from "../../components/misc/Breadcrumb.vue";
import NewsSlider from "../../components/news/Slider.vue";
import SectionNavigation from "../../components/sections/Navigation.vue";
import OverviewGallery from "../../components/misc/OverviewGallery.vue";

export default defineComponent({
  components: {
    Breadcrumb,
    NewsSlider,
    SectionNavigation,
    OverviewGallery,
  },
  methods: {
    getImage,
    fetchSection: async function (id) {
      const sectionsResult = await sections.getSectionBySlug(id);
      this.section = sectionsResult.data.shift();
    },
    getMainImagePosition(position) {
      return position ? position : "center";
    },
  },
  data() {
    return {
      section: {
        settings: {
          title: "",
        },
      },
      //contact: {},
      //length,
    };
  },
  watch: {
    $route(route) {
      this.fetchSection(route.params.slug);
    },
  },
  async mounted() {
    this.fetchSection(this.$route.params.slug);
  },
});
</script>

<style scoped>
h5 {
  color: grey;
}
h5 div {
  font-size: 30px;
}
.text-primary {
  margin-top: 10px;
}
</style>
